// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algoritma-veriyapisi-js": () => import("./../../../src/pages/algoritma-veriyapisi.js" /* webpackChunkName: "component---src-pages-algoritma-veriyapisi-js" */),
  "component---src-pages-antipatterns-js": () => import("./../../../src/pages/antipatterns.js" /* webpackChunkName: "component---src-pages-antipatterns-js" */),
  "component---src-pages-arduino-js": () => import("./../../../src/pages/arduino.js" /* webpackChunkName: "component---src-pages-arduino-js" */),
  "component---src-pages-aws-js": () => import("./../../../src/pages/aws.js" /* webpackChunkName: "component---src-pages-aws-js" */),
  "component---src-pages-chrome-nasil-calisir-js": () => import("./../../../src/pages/chrome-nasil-calisir.js" /* webpackChunkName: "component---src-pages-chrome-nasil-calisir-js" */),
  "component---src-pages-dailynotes-js": () => import("./../../../src/pages/dailynotes.js" /* webpackChunkName: "component---src-pages-dailynotes-js" */),
  "component---src-pages-dogal-dil-isleme-js": () => import("./../../../src/pages/dogal-dil-isleme.js" /* webpackChunkName: "component---src-pages-dogal-dil-isleme-js" */),
  "component---src-pages-dom-api-kullanimi-js": () => import("./../../../src/pages/dom-api-kullanimi.js" /* webpackChunkName: "component---src-pages-dom-api-kullanimi-js" */),
  "component---src-pages-faydali-blog-analizleri-js": () => import("./../../../src/pages/faydali-blog-analizleri.js" /* webpackChunkName: "component---src-pages-faydali-blog-analizleri-js" */),
  "component---src-pages-fe-interview-js": () => import("./../../../src/pages/fe-interview.js" /* webpackChunkName: "component---src-pages-fe-interview-js" */),
  "component---src-pages-frontend-ecosystem-js": () => import("./../../../src/pages/frontend-ecosystem.js" /* webpackChunkName: "component---src-pages-frontend-ecosystem-js" */),
  "component---src-pages-frontend-ideas-js": () => import("./../../../src/pages/frontend-ideas.js" /* webpackChunkName: "component---src-pages-frontend-ideas-js" */),
  "component---src-pages-game-and-bi-js": () => import("./../../../src/pages/game-and-bi.js" /* webpackChunkName: "component---src-pages-game-and-bi-js" */),
  "component---src-pages-gelistirici-araclari-js": () => import("./../../../src/pages/gelistirici-araclari.js" /* webpackChunkName: "component---src-pages-gelistirici-araclari-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-kavramlari-js": () => import("./../../../src/pages/internet-kavramlari.js" /* webpackChunkName: "component---src-pages-internet-kavramlari-js" */),
  "component---src-pages-javascript-async-programming-js": () => import("./../../../src/pages/javascript-async-programming.js" /* webpackChunkName: "component---src-pages-javascript-async-programming-js" */),
  "component---src-pages-javascript-deep-learning-js": () => import("./../../../src/pages/javascript-deep-learning.js" /* webpackChunkName: "component---src-pages-javascript-deep-learning-js" */),
  "component---src-pages-javascript-ile-fonksiyonel-programlama-js": () => import("./../../../src/pages/javascript-ile-fonksiyonel-programlama.js" /* webpackChunkName: "component---src-pages-javascript-ile-fonksiyonel-programlama-js" */),
  "component---src-pages-javascript-metaprogramming-js": () => import("./../../../src/pages/javascript-metaprogramming.js" /* webpackChunkName: "component---src-pages-javascript-metaprogramming-js" */),
  "component---src-pages-javascript-usage-js": () => import("./../../../src/pages/javascript-usage.js" /* webpackChunkName: "component---src-pages-javascript-usage-js" */),
  "component---src-pages-javascriptin-tarihcesi-js": () => import("./../../../src/pages/javascriptin-tarihçesi.js" /* webpackChunkName: "component---src-pages-javascriptin-tarihcesi-js" */),
  "component---src-pages-learn-react-ui-dev-news-js": () => import("./../../../src/pages/learn-react-ui-dev-news.js" /* webpackChunkName: "component---src-pages-learn-react-ui-dev-news-js" */),
  "component---src-pages-medium-istatistikleri-detay-js": () => import("./../../../src/pages/medium-istatistikleri-detay.js" /* webpackChunkName: "component---src-pages-medium-istatistikleri-detay-js" */),
  "component---src-pages-mimari-deneyim-js": () => import("./../../../src/pages/mimari-deneyim.js" /* webpackChunkName: "component---src-pages-mimari-deneyim-js" */),
  "component---src-pages-mimari-kalite-js": () => import("./../../../src/pages/mimari-kalite.js" /* webpackChunkName: "component---src-pages-mimari-kalite-js" */),
  "component---src-pages-mimari-kavramlar-js": () => import("./../../../src/pages/mimari-kavramlar.js" /* webpackChunkName: "component---src-pages-mimari-kavramlar-js" */),
  "component---src-pages-mimari-oruntuler-js": () => import("./../../../src/pages/mimari-oruntuler.js" /* webpackChunkName: "component---src-pages-mimari-oruntuler-js" */),
  "component---src-pages-note-updates-js": () => import("./../../../src/pages/note-updates.js" /* webpackChunkName: "component---src-pages-note-updates-js" */),
  "component---src-pages-oop-tasarim-oruntuleri-js": () => import("./../../../src/pages/oop-tasarim-oruntuleri.js" /* webpackChunkName: "component---src-pages-oop-tasarim-oruntuleri-js" */),
  "component---src-pages-react-architecture-js": () => import("./../../../src/pages/react-architecture.js" /* webpackChunkName: "component---src-pages-react-architecture-js" */),
  "component---src-pages-react-digital-garden-news-js": () => import("./../../../src/pages/react-digital-garden-news.js" /* webpackChunkName: "component---src-pages-react-digital-garden-news-js" */),
  "component---src-pages-react-hooks-js": () => import("./../../../src/pages/react-hooks.js" /* webpackChunkName: "component---src-pages-react-hooks-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-react-knowledge-maps-js": () => import("./../../../src/pages/react-knowledge-maps.js" /* webpackChunkName: "component---src-pages-react-knowledge-maps-js" */),
  "component---src-pages-react-lab-js": () => import("./../../../src/pages/react-lab.js" /* webpackChunkName: "component---src-pages-react-lab-js" */),
  "component---src-pages-react-microfrontends-js": () => import("./../../../src/pages/react-microfrontends.js" /* webpackChunkName: "component---src-pages-react-microfrontends-js" */),
  "component---src-pages-react-network-js": () => import("./../../../src/pages/react-network.js" /* webpackChunkName: "component---src-pages-react-network-js" */),
  "component---src-pages-react-npm-package-js": () => import("./../../../src/pages/react-npm-package.js" /* webpackChunkName: "component---src-pages-react-npm-package-js" */),
  "component---src-pages-react-page-samples-js": () => import("./../../../src/pages/react-page-samples.js" /* webpackChunkName: "component---src-pages-react-page-samples-js" */),
  "component---src-pages-react-performance-js": () => import("./../../../src/pages/react-performance.js" /* webpackChunkName: "component---src-pages-react-performance-js" */),
  "component---src-pages-react-redux-js": () => import("./../../../src/pages/react-redux.js" /* webpackChunkName: "component---src-pages-react-redux-js" */),
  "component---src-pages-react-router-js": () => import("./../../../src/pages/react-router.js" /* webpackChunkName: "component---src-pages-react-router-js" */),
  "component---src-pages-react-server-components-js": () => import("./../../../src/pages/react-server-components.js" /* webpackChunkName: "component---src-pages-react-server-components-js" */),
  "component---src-pages-react-state-js": () => import("./../../../src/pages/react-state.js" /* webpackChunkName: "component---src-pages-react-state-js" */),
  "component---src-pages-react-ui-mechanics-js": () => import("./../../../src/pages/react-ui-mechanics.js" /* webpackChunkName: "component---src-pages-react-ui-mechanics-js" */),
  "component---src-pages-react-ui-styling-js": () => import("./../../../src/pages/react-ui-styling.js" /* webpackChunkName: "component---src-pages-react-ui-styling-js" */),
  "component---src-pages-react-visualization-samples-js": () => import("./../../../src/pages/react-visualization-samples.js" /* webpackChunkName: "component---src-pages-react-visualization-samples-js" */),
  "component---src-pages-react-webpack-js": () => import("./../../../src/pages/react-webpack.js" /* webpackChunkName: "component---src-pages-react-webpack-js" */),
  "component---src-pages-rendering-techniques-js": () => import("./../../../src/pages/rendering-techniques.js" /* webpackChunkName: "component---src-pages-rendering-techniques-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tag-validator-js": () => import("./../../../src/pages/tag-validator.js" /* webpackChunkName: "component---src-pages-tag-validator-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-pages-typescript-basics-js": () => import("./../../../src/pages/typescript-basics.js" /* webpackChunkName: "component---src-pages-typescript-basics-js" */),
  "component---src-pages-uix-design-js": () => import("./../../../src/pages/uix-design.js" /* webpackChunkName: "component---src-pages-uix-design-js" */),
  "component---src-pages-urun-ve-kutuphaneler-js": () => import("./../../../src/pages/urun-ve-kutuphaneler.js" /* webpackChunkName: "component---src-pages-urun-ve-kutuphaneler-js" */),
  "component---src-pages-visualization-and-charts-js": () => import("./../../../src/pages/visualization-and-charts.js" /* webpackChunkName: "component---src-pages-visualization-and-charts-js" */),
  "component---src-pages-web-api-kullanimi-js": () => import("./../../../src/pages/web-api-kullanimi.js" /* webpackChunkName: "component---src-pages-web-api-kullanimi-js" */),
  "component---src-pages-web-test-performans-js": () => import("./../../../src/pages/web-test-performans.js" /* webpackChunkName: "component---src-pages-web-test-performans-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */)
}

