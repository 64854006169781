import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  Axios: "958cab75e45b",
  "HTTP Interceptors": "958cab75e45b",
  "Mock Server": "d9c794146edc",
  "Mock Server Rest API": "16c37afe4447",
  "Mock Server GraphQL": "141792746a44",
}

const stories = [
  {
    title: "Axios Interceptor Kullanım Alanları",
    postId: "958cab75e45b",
    postIdEnglish: "24f1474c9baf",
  },
  {
    title: "(MSW) Mock Server Kullanımı",
    postId: "d9c794146edc",
  },
  {
    title: "(MSW) Mock Server Rest API CRUD Kullanımı",
    postId: "16c37afe4447",
  },
  {
    title: "(MSW) Mock Server GraphQL CRUD Kullanımı",
    postId: "141792746a44",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Network",
  path: "react-network",
}

const ReactNetworkPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactNetworkPage
