import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const LinkMap = {}

const storiesFoundation = [
  {
    title: "React Animations",
    postId: "e019a4811516",
  },
  {
    title: "React Audio",
    postId: "c7b0595447fa",
  },
  {
    title: "React Colors",
    postId: "06abc15c5b12",
  },
]

const storiesCore = []

const storiesLayout = [
  {
    title: "Pixel Layout",
    postId: "0eaee96b9cb5",
  },
]

const storiesBlock = []

const storiesVisualization = [
  {
    title: "React Charts",
    postId: "d57b178587dd",
  },
  {
    title: "React Cytoscape",
    postId: "45dd84a1507d",
  },
  {
    title: "React FlameGraph",
    postId: "e9d9e6ab0db5",
  },
  {
    title: "React Flow",
    postId: "2cbb0bab4404",
  },
  {
    title: "React Heatmap",
    postId: "6a192db6624e",
  },
  {
    title: "React Map",
    postId: "bef3f3849b32",
  },
  {
    title: "React Periodic Table",
    postId: "e78acf188972",
  },
  {
    title: "React Statistics",
    postId: "3afb497f12ed",
  },
  {
    title: "React TraceChart",
    postId: "124e2c918e47",
  },
  {
    title: "React Visjs",
    postId: "e53f03ff4db5",
  },
]

const storiesDevelopment = [
  {
    title: "React GitHub Explorer",
    postId: "891c92a2a65c",
  },
]

const storiesDesigner = []

const storiesPage = []

const storiesGroup = [
  {
    title: "Foundations",
    storyPart: storiesFoundation,
  },
  {
    title: "Core",
    storyPart: storiesCore,
  },
  {
    title: "Layout",
    storyPart: storiesLayout,
  },
  {
    title: "Block",
    storyPart: storiesBlock,
  },
  {
    title: "Visualization",
    storyPart: storiesVisualization,
  },
  {
    title: "Development",
    storyPart: storiesDevelopment,
  },
  {
    title: "Designer",
    storyPart: storiesDesigner,
  },
  {
    title: "Page",
    storyPart: storiesPage,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Knowledge Maps",
  path: "react-knowledge-maps",
}

const ReactKnowledgeMapsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactKnowledgeMapsPage
